// https://fontawesome.com/v5/search

// IMPORTANT: run `pnpm fa-subset` after changing this file or new icons won't show up

export const archiveIcon = 'fas fa-archive';
export const updateIcon = 'fas fa-arrow-circle-up';
export const rightArrowIcon = 'fas fa-arrow-right';
export const moveIcon = rightArrowIcon;
export const banIcon = 'fas fa-ban';
export const menuIcon = 'fas fa-bars';
export const boltIcon = 'fas fa-bolt';
export const faCalculator = 'fas fa-calculator';
export const faCheck = 'fas fa-check';
export const faCheckCircle = 'fas fa-check-circle';
export const faCheckSquare = 'fas fa-check-square';
export const faSquare = 'far fa-square';
export const enabledIcon = faCheckCircle;
export const unselectedCheckIcon = 'far fa-check-circle';
export const settingsIcon = 'fas fa-cog';
export const copyIcon = 'fas fa-copy';
export const downloadIcon = 'fas fa-file-export';
export const sendIcon = 'fas fa-envelope';
export const clearIcon = 'fas fa-eraser';
export const levellingIcon = 'fas fa-level-up-alt';
export const lockIcon = 'fas fa-lock';
export const unlockedIcon = 'fas fa-unlock';
export const faCaretDown = 'fas fa-caret-down';
export const collapseIcon = faCaretDown;
export const editIcon = 'fas fa-pencil-alt';
export const plusIcon = 'fas fa-plus';
export const minusIcon = 'fas fa-minus';
export const expandIcon = 'fas fa-caret-right';
export const faAngleRight = 'fas fa-angle-right';
export const faAngleLeft = 'fas fa-angle-left';
export const maximizeIcon = 'fas fa-angle-double-left';
export const minimizeIcon = 'fas fa-angle-double-right';
export const addIcon = 'fas fa-plus-circle';
export const faPlusSquare = 'fas fa-plus-square';
export const helpIcon = 'far fa-question-circle';
export const infoIcon = 'fas fa-info-circle';
export const searchIcon = 'fas fa-search';
export const signOutIcon = 'fas fa-sign-out-alt';
export const expandDownIcon = 'fas fa-chevron-down';
export const expandUpIcon = 'fas fa-chevron-up';
export const moveDownIcon = 'fas fa-arrow-down';
export const moveUpIcon = 'fas fa-arrow-up';
export const shareIcon = 'fas fa-share';
export const shoppingCart = 'fas fa-shopping-cart';
export const starIcon = 'fas fa-star';
export const starOutlineIcon = 'far fa-star';
export const refreshIcon = 'fas fa-sync';
export const spreadsheetIcon = 'fas fa-table';
export const tagIcon = 'fas fa-tag';
export const faList = 'fas fa-list';
export const faGrid = 'fas fa-th';
export const thumbsUpIcon = 'fas fa-thumbs-up';
export const thumbsDownIcon = 'fas fa-thumbs-down';
export const closeIcon = 'fas fa-times';
export const faTimesCircle = 'fas fa-times-circle';
export const faWindowClose = 'fas fa-window-close';
export const disabledIcon = faTimesCircle;
export const deleteIcon = 'far fa-trash-alt';
export const mastodonIcon = 'fab fa-mastodon';
export const undoIcon = 'fas fa-undo';
export const redoIcon = 'fas fa-redo';
export const uploadIcon = 'fas fa-file-import';
export const heartIcon = 'fas fa-heart';
export const globeIcon = 'fas fa-globe';
export const stickyNoteIcon = 'fas fa-sticky-note';
export const faMinusSquare = 'fas fa-minus-square';
export const faRandom = 'fas fa-random';
export const faEquals = 'fas fa-equals';
export const kebabIcon = 'fas fa-ellipsis-v';
export const faArrowCircleDown = 'fas fa-arrow-circle-down';
export const faArrowCircleUp = 'fas fa-arrow-circle-up';
export const faExclamationCircle = 'fas fa-exclamation-circle';
export const faCaretUp = 'fas fa-caret-up';
export const faClock = 'far fa-clock';
export const faExclamationTriangle = 'fas fa-exclamation-triangle';
export const faTshirt = 'fas fa-tshirt';
export const faExternalLinkAlt = 'fas fa-external-link-alt';
export const trackedIcon = 'fas fa-bookmark';
export const unTrackedIcon = 'far fa-bookmark';
export const compareIcon = 'fas fa-balance-scale-left';
export const dragHandleIcon = 'fas fa-grip-vertical';
export const pinIcon = 'fas fa-thumbtack';
export const faXbox = 'fab fa-xbox';
export const faPlayStation = 'fab fa-playstation';
export const faSteam = 'fab fa-steam';
export const faDiscord = 'fab fa-discord';
export const faGithub = 'fab fa-github';
export const saveIcon = 'fas fa-save';
export const greaterThanIcon = 'fas fa-greater-than-equal';
export const lessThanIcon = 'fas fa-less-than-equal';
export const equalsIcon = 'fas fa-equals';
export const stackIcon = 'fas fa-layer-group';
export const slashIcon = 'fas fa-slash';
