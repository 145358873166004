// Exotic class items' exotic intrinsic sockets don't correspond to plug sets.
// Thus we have to maintain manual lists of what can roll for each.
export const exoticClassItemPlugs: {
  [itemHash: number]: { [socketIndex: number]: number[] | undefined } | undefined;
} = {
  266021826: {
    10: [
      1476923952, 1476923953, 1476923954, 3573490509, 3573490508, 3573490511, 3573490510,
      3573490505,
    ],
    11: [
      1476923955, 1476923956, 1476923957, 3573490504, 3573490507, 3573490506, 3573490501,
      3573490500,
    ],
  },
  2273643087: {
    10: [1476923952, 1476923953, 1476923954, 183430248, 183430255, 183430252, 183430253, 183430250],
    11: [1476923955, 1476923956, 1476923957, 183430251, 183430254, 183430249, 183430246, 183430247],
  },
  2809120022: {
    10: [
      1476923952, 1476923953, 1476923954, 3751917999, 3751917998, 3751917997, 3751917996,
      3751917995,
    ],
    11: [
      1476923955, 1476923956, 1476923957, 3751917994, 3751917993, 3751917992, 3751917991,
      3751917990,
    ],
  },
};
